import "./FeaturedEvent.scss";

function FeaturedEvent() {
  return (
    <>
      <div id="events" className="featured">
        <div
          className="featured__card"
          style={{
            backgroundImage: `url("/images/PhotoGalleries/2023/2023-golf-tournament/Golf2023-3.jpg")`,
          }}
        >
          <div className="featured__text">
            <h2 className="featured__title">Annual Golf Tournament</h2>
            <h3 className="featured__where">{`Location: Glen Abbey Golf Club`}</h3>
            <h3 className="featured__when">July 4, 2024</h3>

            <p className="featured__info">
              The Resilient Flooring Contractors Association of Ontario invites
              members to its annual golf tournament at no cost - to a limit of 2
              foursomes - and unionized contractors only who may attend as
              guests. Cash bar applies.
            </p>
            <a
              className="featured__link featured__link--button"
              href="https://lp.constantcontactpages.com/ev/reg/td6k6re"
              target="_blank"
            >
              Get your ticket here
            </a>
          </div>
          {/* <a
            className="featured__register"
            href="http://events.r20.constantcontact.com/register/event?oeidk=a07ejrdqdvv70db901f&llr=ipefsquab"
            target="_blank"
          >
            Register Now
          </a> */}
        </div>
        {/* <div className="featured__card">
            <div className="featured__text">
              <h2 className="featured__title">2022 Golf Day</h2>
              <h3 className="featured__where">King's Riding Golf Club</h3>
              <h3 className="featured__when">July 12th, 2022</h3>
            </div>
            <button className="featured__register">REGISTER NOW</button>
          </div> */}
      </div>
    </>
  );
}

export default FeaturedEvent;
